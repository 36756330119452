import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalApiUrlService  {
  state: any = "d"
  RACINE_API: any = ""
  PORT_API: any = ""
  CHEMIN_RACINE_SERVER: any = ""
  REST_API: any = ""
  BASE_ROUTE: any = ""


  constructor() { 
    this.urlState(this.state)
  }
  
  urlState(param) {

    if (param == "a") {
      /*config lors développement local */
      this.RACINE_API = 'localhost';
      this.PORT_API = '3000';
      this.BASE_ROUTE = ''
    } else if (param == "b") {
      /*config lors déploiement server*/
      this.RACINE_API = '208.109.241.173';
      this.PORT_API = '778';
      this.BASE_ROUTE = 'hecla'
    }
    else if(param == "c"){
      /*config lors déploiement server*/
      this.RACINE_API = 'formationnsc-fd.com';
      this.PORT_API = '';
      this.BASE_ROUTE = 'hecla'
    }
    else {
      /*config lors déploiement server*/
      this.RACINE_API = 'root2rise-nsc-formation-dev.ca';
      this.PORT_API = '';
      this.BASE_ROUTE = 'hecla'
    }

    // this.REST_API = 'http://' + this.RACINE_API + ':' + this.PORT_API + (param == "a" ? "" : `/${this.BASE_ROUTE}`);
    var protocol = 'ab'.includes(param)? 'http': 'https'
    this.REST_API = `${protocol}://${this.RACINE_API}${this.PORT_API? ':' + this.PORT_API: ''}${this.BASE_ROUTE? '/' + this.BASE_ROUTE: ''}`
  } 
}

