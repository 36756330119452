<app-load-page *ngIf="is_loading"></app-load-page>
<div class="px-3 py-2" *ngIf="!is_loading">
    <div class="d-flex flex-row align-items-center justify-content-between">
        <span class="h3">Liste des utilisateurs</span>
        <hr style="width: 65%; background-color: #707fdd; border-radius: 2px; padding: 2px;">
        <button class="btn-special" data-bs-toggle="modal" data-bs-target="#addPersonne" (click)="resetForm()"> <i class="bi bi-person-add me-2"></i> Ajouter un nouveau utilisateur</button>
    </div>
    <div class="mt-3" style="overflow: auto; max-height: 85vh;">
        <div class="row">
            <ng-container *ngFor="let niveau of role_list; let y = index">
                <div class="col-6 mb-3" *ngIf="liste_user_dynamique[niveau.libelle] != undefined">
                    <div class="card">
                        <div class="card-header">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div>
                                    {{niveau.libelle}} <span class="text-secondary" style="font-size: 12px;">({{liste_user_dynamique[niveau.libelle].length}})</span>
                                </div>
                                <input type="text" style="width: 30%; font-size: 12px;" [(ngModel)]="niveau.search_name" (keyup)="search_fullname(niveau.libelle, niveau.search_name)" class="form-control form-control-sm" placeholder="Recherche nom & prénom...">
                            </div>
                        </div>
                        <div class="card-body" style="overflow: auto;">
                            <table class="table table-sm table-bordered" style="vertical-align: middle; text-align: center;">
                                <thead>
                                    <tr>
                                        <th style="width: 28%;" (click)="trieParKey('nom_prenom', niveau.libelle)">Nom & prénom <i [class]="icon_trie" *ngIf="affiche_icon[niveau.libelle].nom_prenom"></i> </th>
                                        <th style="width: 31%;" (click)="trieParKey('email', niveau.libelle)">Email <i [class]="icon_trie" *ngIf="affiche_icon[niveau.libelle].email"></i></th>
                                        <th style="width: 16%;" (click)="trieParKey('user', niveau.libelle)">Nom d'utilisateur <i [class]="icon_trie" *ngIf="affiche_icon[niveau.libelle].user"></i> </th>
                                        <th style="width: 16%;" (click)="trieParKey('password', niveau.libelle)">Mot de passe <i [class]="icon_trie" *ngIf="affiche_icon[niveau.libelle].password"></i> </th>
                                        <th style="width: 15%;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let info of liste_user_dynamique[niveau.libelle]; let i = index">
                                        <tr>
                                            <td>{{info.nom_prenom}}</td>
                                            <td>{{info.email}}</td>
                                            <td>{{info.user}}</td>
                                            <td>{{info.password}}</td>
                                            <td>
                                                <button class="btn btn-sm btn-outline-warning border-0" (click)="toEditUser(info)" data-bs-toggle="modal" data-bs-target="#addPersonne"> <i class="bi bi-pen-fill" style="font-size: 11px;"></i> </button>
                                                <button *ngIf="info_user_log.id_user != info.id_user" class="btn btn-sm btn-outline-danger border-0" (click)="deleteUser(info, niveau.libelle, i)"> <i class="bi bi-trash" style="font-size: 11px;"></i> </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="addPersonne" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h4 *ngIf="!is_editing">Ajout d'utilisateur</h4>
                    <h4 *ngIf="is_editing">Modification d'utilisateur </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" [(ngModel)]="utilisateur_form.nom"
                                    [ngClass]="{'is-invalid':utilisateur_form.nom == '' && is_verifing}"
                                    class="form-control" id="nom" placeholder="name@example.com">
                                <label for="nom">Nom *</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" [(ngModel)]="utilisateur_form.prenom"
                                    [ngClass]="{'is-invalid':utilisateur_form.prenom == '' && is_verifing}" id="prenom"
                                    placeholder="name@example.com">
                                <label for="prenom">Prénom *</label>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-floating mb-2">
                                <input type="email" class="form-control" [(ngModel)]="utilisateur_form.email"
                                    [ngClass]="{'is-invalid':utilisateur_form.email == '' && is_verifing}" id="email"
                                    placeholder="name@example.com">
                                <label for="email">Email *</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect" [(ngModel)]="utilisateur_form.role"
                                    [ngClass]="{'is-invalid':utilisateur_form.role == '' && is_verifing}"
                                    aria-label="Floating label select example" style="font-size: 13px;" [disabled]="info_user_log.id_user == id_user_editing">
                                    <option selected disabled value="">Choisir le rôle</option>
                                    <option *ngFor="let role of role_list; let i = index" [value]="role.codename" style="font-size: 13px;">
                                        {{i+1}} - {{role.libelle}}</option>
                                </select>
                                <label for="floatingSelect">Niveau *</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" [(ngModel)]="utilisateur_form.username"
                                    [ngClass]="{'is-invalid':utilisateur_form.username == '' && is_verifing}"
                                    id="username" placeholder="name@example.com">
                                <label for="username">Nom d'utilisateur *</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input [type]="showPassword ? 'text' : 'password'"
                                    [(ngModel)]="utilisateur_form.password"
                                    [ngClass]="{'is-invalid':utilisateur_form.password == '' && is_verifing}"
                                    class="form-control" id="motdepasse" placeholder="name@example.com">
                                <label for="motdepasse">Mot de passe *</label>
                                <button class="clear" aria-label="Clear input" (click)="showPassword = !showPassword">
                                    <i [ngClass]="{'bi bi-eye':showPassword,'bi bi-eye-slash':!showPassword}"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3" *ngIf="!is_editing">
                    <button class="btn btn-sm btn-success" (click)="saveUtilisateur()">Enregistrer</button>
                    <button class="btn btn-sm btn-secondary ms-2" (click)="resetForm()">Réinitialiser</button>
                    <span class="text-secondary ms-3" style="font-size: 11px;">(*) Champs obligatoire</span>
                </div>
                <div class="mt-3" *ngIf="is_editing">
                    <button class="btn btn-sm btn-warning" (click)="saveEditingUser()" data-bs-dismiss="modal">Enregistrer</button>
                    <button class="btn btn-sm btn-secondary ms-2" data-bs-dismiss="modal">Annuler</button>
                    <span class="text-secondary ms-3" style="font-size: 11px;">(*) Champs obligatoire</span>
                </div>
            </div>
        </div>
    </div>
</div>