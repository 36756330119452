<div id="body-login" *ngIf="!onLog">
    <div class="rounded-top shadow"></div>
    <div class="rounded-bottom shadow"></div>
    <div class="main shadow-lg">
        <input type="checkbox" id="chk" aria-hidden="true">

        <div class="signup">
            <form (ngSubmit)="login()" #loginForm="ngForm">
                <label for="chk" aria-hidden="true">Connexion</label>
                <input type="email" name="email" style="font-size: 14px;" [(ngModel)]="matricule" placeholder="Nom d'utililsateur" required="">
                <div class="input-wrapper">
                    <input [type]="showPassword ? 'text' : 'password'" name="pswd" style="font-size: 14px;" [(ngModel)]="password" placeholder="Mot de passe" required="">
                    <div class="eye-icon" aria-label="Clear input" (click)="showPassword = !showPassword">
                        <i [ngClass]="{'bi bi-eye':showPassword,'bi bi-eye-slash':!showPassword}"></i>
                    </div>
                </div>
                <button>Login</button>
            </form>
        </div>
        
        <div class="login text-center">
            <form>
                <label for="chk" aria-hidden="true">Inscription</label>
                <input type="text" name="txt" placeholder="Nom" required="" disabled>
                <input type="text" name="txt" placeholder="Prénom" required="" disabled>
                <input type="email" name="email" placeholder="Email" required="" disabled>
                <span class="text-secondary">En cours de développement</span>
            </form>
        </div>

    </div>
</div>

<div *ngIf="onLog">
    <app-body-form></app-body-form>
</div>