import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profil-rh',
  templateUrl: './profil-rh.component.html',
  styleUrls: ['./profil-rh.component.css']
})
export class ProfilRhComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  data_profil_rh:any = [
    {
      image:"assets/images/RH/goudreault-removebg-preview.png",
      nom:"Mariane Goudreault", 
      poste:"Conseillière RH",
      departement:["Usine", "Environnement", "Entrepôt/Achats", "Administration VD", "Administration", "Ingénierie", "Géologie/Exploration VD", "Electrique", "Etudiants"],
      taches:["Comité cadre", "Logements", "Bourse d'études/Stages", "Rétention"],
      telephone:"819-339-0236",
      courriel:"mgoudreault@hecla.com",
      qr_code:"assets/images/QR CODE/mgoudreault.png"
    },
    {
      image:"assets/images/RH/trembley-removebg-preview.png",
      nom:"Marilyn Tremblay", 
      poste:"Conseillière senior RH",
      departement:["Sous terre", "Fosse", "Mécanique mobile et fixe", "SST et Formation"],
      taches:["Comité horaire", "Autobus", "Cohorte d'extraction minerais", "Rétention"],
      telephone:"819-339-0272",
      courriel:"matrembley@hecla.com",
      qr_code:"assets/images/QR CODE/trembley.jpg"
    },
    {
      image:"assets/images/RH/labrecque-removebg-preview.png",
      nom:"Mélanie Labrecque", 
      poste:"Technicienne RH",
      departement:["Entrepreneurs"],
      taches:["Ajouts/retraits de personnel", "Carte d'autobus", "Badge sous terre", "Lampe mineurs", "Vignettes de stationnement"],
      telephone:"819-339-0481",
      courriel:"mlabrecque@hecla.com",
      qr_code:"assets/images/QR CODE/labrecque.jpg"
    },
    {
      image:"assets/images/RH/larouche-removebg-preview.png",
      nom:"Mélissa Larouche", 
      poste:"Coordonnatrice des communications et relations avec les partenaires",
      departement:["N/A"],
      taches:["Commandites", "Reconnaissance ancienneté"],
      telephone:"819-339-0258",
      courriel:"melarouche@hecla.com",
      qr_code:"assets/images/QR CODE/larouche.jpg"
    },
    {
      image:"assets/images/RH/farrell-removebg-preview.png",
      nom:"Arnaud Farrell", 
      poste:"Coordonnateur senior RH",
      departement:["Ressources Humaines"],
      taches:["Rémunération", "Avantages sociaux"],
      telephone:"819-339-0411",
      courriel:"afarrell@hecla.com",
      qr_code:"assets/images/QR CODE/farrell.png"
    },
    {
      image:"assets/images/RH/julien-removebg-preview.png",
      nom:"Malika Julien", 
      poste:"Conseillière RH",
      departement:["Processus de dotation externe (Tous les départements)", "Etudiants et stagiaires"],
      taches:["Affichage de poste", "Sortir les Curriculums Vitaes", "Planification de l'entrevue", "Planification de l'arrivée de l'employé"],
      telephone:"819-339-0237",
      courriel:"mjulien@hecla.com",
      qr_code:"assets/images/QR CODE/julien.jpg"
    }
  ]

}
