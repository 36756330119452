<div class="px-4">
    <div class="d-flex flex-row align-items-center justify-content-between mt-2">
        <div class="text-start">
            <span style="font-size: 18px;" class="text-special fw-bold">PROBLEME AVEC ADP?</span> <br>
            <span style="font-size: 13px;"><span style="font-size: 13px; font-weight: bold;">Téléphone : </span> 1 844-248-3590 </span> <br>
            <span style="font-size: 13px;"><span style="font-size: 13px; font-weight: bold;">Courriel : </span> {{'CANHRServicesEmployee@adp.com'}} </span>
        </div>
        <div>
            <span style="font-size: 25px;">L'EQUIPE RESSOURCES HUMAINES</span>
        </div>
        <div class="text-end">
            <span style="font-size: 18px;" class="text-special fw-bold">POUR DES QUESTIONS AU NIVEAU DE LA PAIE :</span> <br>            
            <span>Relevé d'emploi - Question sur votre paie - Relevé d'impôt</span> <br>
            <span style="font-size: 13px;"><span style="font-size: 13px; font-weight: bold;">Courriel : </span> {{'hqc-vd-paie@hecla.com'}} </span>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between mt-4" style="overflow: auto; max-height: 85vh;">
        <ng-container *ngFor="let item of data_profil_rh; let i = index">
            <div>
                <div class="card" style="width: 17rem;">
                    <div style="margin: auto;">
                        <img [src]="item.image" *ngIf="i == 0" style="margin-left: 10px;" alt="IMAGE" width="175" height="110">
                        <img [src]="item.image" *ngIf="i == 1" style="margin-left: 4px;" alt="IMAGE" width="160" height="110">
                        <img [src]="item.image" *ngIf="i == 2" style="margin-left: 4px;" alt="IMAGE" width="170" height="110">
                        <img [src]="item.image" *ngIf="i == 3" style="margin-left: 43px; margin-top: -6px;" alt="IMAGE" width="170" height="115">
                        <img [src]="item.image" *ngIf="i == 4" style="margin-left: 10px; margin-top: -8px;" alt="IMAGE" width="170" height="120">
                        <img [src]="item.image" *ngIf="i == 5" style="margin-left: 4px;" alt="IMAGE" width="170" height="110">
                        <div class="card-body text-center">
                            <h6 class="card-title">{{item.nom}}</h6>
                            <p class="card-text">{{item.poste}}</p>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div>
                                <span class="fw-bold" *ngIf="i <= 1">Département interne :</span>
                                <span class="fw-bold" *ngIf="i > 1 && i < data_profil_rh.length-1">Département :</span>
                                <span class="fw-bold" *ngIf="i == data_profil_rh.length-1">Candidatures externes :</span>
                            </div>
                            <ul>
                                <li *ngFor="let departement of item.departement">{{departement}}</li>
                            </ul>
                        </li>
                        <li class="list-group-item">
                            <div>
                                <span class="fw-bold">Tâches :</span>
                            </div>
                            <ul>
                                <li *ngFor="let tache of item.taches">{{tache}}</li>
                            </ul>
                        </li>
                        <li class="list-group-item"> <span class="fw-bold">Téléphone : </span> {{item.telephone}} </li>
                        <li class="list-group-item"> <span class="fw-bold">Courriel : </span> {{item.courriel}} </li>
                    </ul>
                    <div class="card-body" style="margin:auto;">
                        <img [src]="item.qr_code" alt="QR CODE" width="120" height="120">
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>