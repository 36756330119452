import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from './services/toast/toast.service';
import { UsersService } from './services/users/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  onLog: boolean = false

  constructor(
    private cookies: CookieService,
    private userService: UsersService,
    private toast: ToastService
  ) {
    const cookieExists = this.cookies.check("auth")
    this.onLog = cookieExists
  }

  matricule: any
  password: any

  ngOnInit() { }

  logout() {
    this.cookies.delete("auth")
    this.onLog = false
    this.password = ""
    // this.cookies.delete("data_perso")
  }

  isNullOrUndefined(variable: any) {
    return variable == undefined || variable == null || variable == "" ? true : false
  }

  resetLogin() {
    this.matricule = ""
    this.password = ""
  }

  login() {
    if (this.isNullOrUndefined(this.matricule) || this.isNullOrUndefined(this.password)) {
      this.toast.Warning("Veuillez remplir les champs")
    } else {
      this.userService.login({
        user: this.matricule,
        password: this.password
      }).subscribe({
        next: (data: { auth?: string }) => {
          if (data.auth) {
              this.cookies.set('auth', data.auth)
              this.onLog = true
          }
          else {
            this.toast.Error("Utilisateur ou mot de passe incorrect")
          }
        },
        error: () => {
          this.toast.Error("Utilisateur ou mot de passe incorrect")
        }
      })
    }
  }

}


