import { Component, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { DateTime } from 'luxon';
import { UsersService } from 'src/app/services/users/users.service';


interface Appointment {
  uuid?: string;
  date: Date;
  title: string;
  startTime: string;
  endTime: string;
  color?: string;
}

export enum CalendarView {
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.css']
})
export class CalendrierComponent {

  constructor(
    private modalService: NgbModal,
    private calendrierService: CalendrierService,
    private userService:UsersService
  ) {
    this.getUser()
    this.generateView(this.currentView, this.viewDate);
    this.generateTimeSlots()
    this.getDataCalendrier()
  }
  user_log:any = []
  access:any = false
  role_access:any = [99,10]
  is_admin:any = false

  getUser(){
    this.userService.getUserService({}).subscribe((data:any) => {
      this.user_log = data
      this.is_admin = this.role_access.includes(this.user_log.role)
      // console.log(this.is_admin)
    })
  }

  openModal(row: any) {
    const modalRef = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true
    });
    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.formations = this.formations_dynamique;
    modalRef.componentInstance.is_admin = this.is_admin;
    modalRef.componentInstance.user_log = this.user_log;
  }

  viewDate: Date = new Date();
  selectedDate: Date | null = null;
  selectedStartTime: string | undefined;
  weekDays: string[] = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  monthDays: Date[] = [];
  appointments: Appointment[] = [
    {
      uuid: '00000000-0000-0000-0000-000000000001',
      date: new Date("2024-12-17"),
      title: 'Meeting with Bob',
      startTime: '09:00',
      endTime: '10:00',
    }
  ];
  currentView: CalendarView = CalendarView.Month;
  timeSlots: string[] = [];
  weeks: Date[][] = [];
  public formations: any
  public formations_dynamique:any
  liste_formateur:any = []
  formateur_filtered:any = ""
  nombre_formation_mois:any = 0

  public CalendarView = CalendarView;

  switchView(view: CalendarView) {
    this.currentView = view;
    this.generateView(this.currentView, this.viewDate);
  }

  generateView(view: CalendarView, date: Date) {
    switch (view) {
      case CalendarView.Month:
        this.generateMonthView(date);
        break;
      case CalendarView.Week:
        this.generateWeekView(date);
        break;
      default:
        this.generateMonthView(date);
    }
  }

  generateMonthView(date: Date) {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.weeks = [];
    this.monthDays = [];
    let week: Date[] = [];

    for (let day = start.getDay(); day > 0; day--) {
      const prevDate = new Date(start);
      prevDate.setDate(start.getDate() - day);
      week.push(prevDate);
      this.monthDays.push(prevDate);
    }

    for (let day = 1; day <= end.getDate(); day++) {
      const currentDate = new Date(date.getFullYear(), date.getMonth(), day);
      this.monthDays.push(currentDate);
      week.push(currentDate);

      if (week.length === 7) {
        this.weeks.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      for (let day = 1; week.length < 7; day++) {
        const nextDate = new Date(end);
        nextDate.setDate(end.getDate() + day);
        week.push(nextDate);
      }
      this.weeks.push(week);
    }
  }


  generateWeekView(date: Date) {
    const startOfWeek = this.startOfWeek(date);
    this.monthDays = [];

    for (let day = 0; day < 7; day++) {
      const weekDate = new Date(startOfWeek);
      weekDate.setDate(startOfWeek.getDate() + day);
      this.monthDays.push(weekDate);
    }

    // console.log(this.monthDays)
  }


  generateTimeSlots() {
    for (let hour = 0; hour <= 24; hour++) {
      const time = hour < 10 ? `0${hour}:00` : `${hour}:00`;
      this.timeSlots.push(time);
    }
  }

  startOfWeek(date: Date): Date {
    const start = new Date(date);
    const day = start.getDay();
    const diff = day === 0 ? 0 : -day;
    return new Date(start.setDate(start.getDate() + diff));
  }


  previous() {
    if (this.currentView === 'month') {
      this.viewDate = new Date(
        this.viewDate.setMonth(this.viewDate.getMonth() - 1)
      );
      this.generateMonthView(this.viewDate);
    } else if (this.currentView === 'week') {
      this.viewDate = new Date(
        this.viewDate.setDate(this.viewDate.getDate() - 7)
      );
      this.generateWeekView(this.viewDate);
    }
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month).length
  }

  next() {
    if (this.currentView === 'month') {
      this.viewDate = new Date(
        this.viewDate.setMonth(this.viewDate.getMonth() + 1)
      );
      this.generateMonthView(this.viewDate);
    } else if (this.currentView == 'week') {
      this.viewDate = new Date(
        this.viewDate.setDate(this.viewDate.getDate() + 7)
      );
      this.generateWeekView(this.viewDate);
    }
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month).length
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isSelected(date: Date): boolean {
    if (!this.selectedDate) {
      return false;
    }
    return (
      date.getDate() === this.selectedDate.getDate() &&
      date.getMonth() === this.selectedDate.getMonth() &&
      date.getFullYear() === this.selectedDate.getFullYear()
    );
  }

  isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  selectDate(date?: Date, startTime?: string) {
    if (date) {
      this.selectedDate = date;
    } else {
      this.selectedDate = new Date();
    }
    this.selectedStartTime = startTime;
  }

  getAppointmentsForDate(day: Date, timeSlots: string[]) {
    return this.appointments
      .filter((appointment) => {
        return this.isSameDate(appointment.date, day);
      })
      .map((appointment) => {
        const startTimeIndex = timeSlots.indexOf(appointment.startTime);
        const endTimeIndex = timeSlots.indexOf(appointment.endTime);
        return { ...appointment, startTimeIndex, endTimeIndex };
      });
  }

  // drop(event: CdkDragDrop<Appointment[]>, date: Date, slot?: string) {
  //   const movedAppointment = event.item.data;
  //   movedAppointment.date_prevue = date    
  //   var dataObj = {
  //     date_prevue: DateTime.fromJSDate(date).setZone('UTC').toFormat("yyyy-MM-dd"),
  //     ids:movedAppointment.travailleurs.map(t => t.id_travailleur_formation).join(',')
  //   }
  //   console.log(dataObj)
  //   this.calendrierService.updateDateFormationService(dataObj).subscribe()
  // }

  viewToday(): void {
    this.viewDate = DateTime.now().setZone('UTC').toJSDate();
    if (this.currentView == 'month') {
      this.generateMonthView(this.viewDate);
    } else if (this.currentView == 'week') {
      this.generateWeekView(this.viewDate);
    }
  }

  isCurrentMonth(date: Date): boolean {
    return (
      date.getMonth() === this.viewDate.getMonth() &&
      date.getFullYear() === this.viewDate.getFullYear()
    );
  }

  getAppointmentsForDateTime(date: Date, timeSlot: string): Appointment[] {
    const result = this.formations_dynamique.filter((item) =>
      this.isSameDate(item.date_prevue, date) && item.heure_debut <= timeSlot && item.heure_fin > timeSlot
    )
    return result;
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.3;
    return `rgba(${r},${g},${b},${a})`;
  }

  is_loading: any = false
  getDataCalendrier() {
    this.is_loading = true
    var dataObj = {}
    this.calendrierService.getDataCalendrierService(dataObj).subscribe((data: any) => {
      data.forEach(item => item.date_prevue = new Date(item.date_prevue))
      this.formations = data
      this.formations.forEach((item) => {
        item.color = item.color_formateur[0];
        item.class = item.statut_demande == 0 ? "close_inscription" : "open_inscription"
        let date_format = DateTime.fromJSDate(item.date_prevue).toFormat("yyyy-MM-dd")
        if (date_format != item.date_prevue_text) {
          item.date_prevue = DateTime.fromJSDate(item.date_prevue).plus({ days: 1 }).toJSDate()
        }
        item.initial_formateur = this.obtenirInitiales(item.formateurs[0])
        item.heure_debut = DateTime.fromFormat(item.heure_debut, "HH:mm:ss").toFormat("HH:mm")
        item.heure_fin = DateTime.fromFormat(item.heure_fin, "HH:mm:ss").toFormat("HH:mm")
      });
      this.formations.sort((a, b) => {
        const heureDebutA = DateTime.fromFormat(a.heure_debut, "HH:mm");
        const heureDebutB = DateTime.fromFormat(b.heure_debut, "HH:mm");
        return heureDebutA.ts - heureDebutB.ts;
      });
      this.formations_dynamique = [...this.formations]      
      this.liste_formateur = [...new Set(this.formations.flatMap(f => f.formateurs))].map(item => {return {nom:item, initial: this.obtenirInitiales(item)}}).filter(f => f.nom != "")
      this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month).length
      // console.log(this.formations)
      this.is_loading = false
    })
  }

  obtenirInitiales(nomComplet) {
    const mots = nomComplet.split(' ');
    const initiales = mots.map(mot => mot.charAt(0).toUpperCase()).join('.');
    return initiales;
  }

  filteredByFormateur(formateur:any){
    this.formations_dynamique = formateur == "" ? [...this.formations] : [...this.formations].filter(f => f.initial_formateur == formateur)
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month).length
  }

}

