import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalApiUrlService } from '../global-api-url.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CalendrierService {

  constructor(private http: HttpClient, private url: GlobalApiUrlService) { }

  getDataCalendrierService(data:any = null) {
    var API_URL = this.url.REST_API + '/get-calendrier';
    return this.http.post(API_URL, data, httpOptions)
  }

  getDataTableauService() {
    var API_URL = this.url.REST_API + '/get-tableau';
    return this.http.get(API_URL)
  }

  getFormateurService(data:any = null) {
    var API_URL = this.url.REST_API + '/get-formateur';
    return this.http.post(API_URL, data, httpOptions)
  }

  getAgendaService(filter:any = null) {
    var API_URL = this.url.REST_API + '/get-agenda';
    return this.http.post(API_URL, {filter}, httpOptions)
  }
  
  getActiviteService(data:any = null) {
    var API_URL = this.url.REST_API + '/get-activite';
    return this.http.post(API_URL, data)
  }  

  getDateTableauGlobalService() {
    var API_URL = this.url.REST_API + '/get-tableau-global';
    return this.http.get(API_URL)
  }

  getListeFormationService() {
    var API_URL = this.url.REST_API + '/get-formation';
    return this.http.get(API_URL)
  }  
  
  setFormationService(data:any = null) {
    var API_URL = this.url.REST_API + '/set-agenda';
    return this.http.post(API_URL, data, httpOptions)
  }  

  updateAgendaService(data:any = null) {
    var API_URL = this.url.REST_API + '/update-agenda';
    return this.http.post(API_URL, data, httpOptions)
  }

  deleteAgendaService(data:any = null) {
    var API_URL = this.url.REST_API + '/delete-agenda';
    return this.http.post(API_URL, data, httpOptions)
  }

  setActiviteService(data:any = null) {
    var API_URL = this.url.REST_API + '/set-activite';
    return this.http.post(API_URL, data, httpOptions)
  }

  setFormateurService(data:any = null) {
    var API_URL = this.url.REST_API + '/set-formateur';
    return this.http.post(API_URL, data, httpOptions)
  }

  getTableauGlobalService(data:any = null) {
    var API_URL = this.url.REST_API + '/get-tableau-formation-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  openInscriptionService(data:any = null) {
    var API_URL = this.url.REST_API + '/set-demande';
    return this.http.post(API_URL, data, httpOptions)
  }

  saveTravailleurFormationService(data:any = null) {
    var API_URL = this.url.REST_API + '/save-travailleur-formation';
    return this.http.post(API_URL, data, httpOptions)
  }

  deleteTravailleurFormationService(data:any = null) {
    var API_URL = this.url.REST_API + '/delete-travailleur-formation';
    return this.http.post(API_URL, data, httpOptions)
  }

  updateDateFormationService(data:any = null) {
    var API_URL = this.url.REST_API + '/update-date-formation';
    return this.http.post(API_URL, data, httpOptions)
  }

  exportFormationService(data:any = null){
    var API_URL = this.url.REST_API + '/export-formation';
    return this.http.post(API_URL, data, {responseType: 'blob'})
  }

  getStatutCalendrierFormateurService(data:any = null){
    var API_URL = this.url.REST_API + '/get-statut-calendrier-formateur';
    return this.http.post(API_URL, data, httpOptions)
  }

  setStatutCalendrierFormateurService(data:any = null){
    var API_URL = this.url.REST_API + '/set-statut-calendrier-formateur';
    return this.http.post(API_URL, data, httpOptions)
  }
}
