import { Component, OnInit } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css']
})
export class ListeComponent {

  liste_formation:any
  is_loading:any = false

  constructor(
    private calendrierService:CalendrierService
  ) { 
    this.getListeFormation()
  }

  getListeFormation(){
    this.is_loading = true
    this.calendrierService.getListeFormationService().subscribe((data:any) => {
      this.liste_formation = data
      this.is_loading = false
    })
  }

}
