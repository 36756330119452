<div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Importation</h1>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close" *ngIf="!is_uploading"></button>
</div>
<div class="modal-body p-0" style="background-color: #f8f8f8">
    <div class="text-center text-secondary p-4" appDragdrop (dropped)="onFilesDropped($event)" *ngIf="!is_uploading">
        <div>
            <i class="bi bi-file-earmark-excel fs-2"></i> 
            <i class="bi bi-chevron-right fs-5 me-1 ms-1"></i>
            <i class="bi bi-database fs-2"></i>
        </div>
        <span style="font-size: 16px;">Déposez votre fichier ici</span>
    </div>
    <div class="text-center text-secondary p-4" *ngIf="is_uploading">
        <div class="spinner-grow spinner-grow-sm text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div>
            <span style="font-size: 16px;">Importation des données</span>
        </div>
    </div>
</div>