import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent {

  searchTravailleur:any = ""
  travailleurs_liste:any 
  info_individu:any = {
    nom_travailleur: "",
    prenom_travailleur: "",
    date_naissance: "",
    sexe_travailleur: "",
    id_personne: "",
    matricule: "",
    entrepreneur: "",
    date_emploi: "",
    statut: "",
    code: "",
    departement: "",
    sous_departement: "",
    equipe: "",
    horaire: "",
    id_fonction: "",
    fonction: "",
    realite: "",
    matricule_sup: "",
    sup_imm: "",
  }
  is_loading:any = false

  constructor(
    private travailleurService:TravailleurService,
    private toast:ToastService
  ) { 
    this.getDateTravailleur()
  }

  getDateTravailleur(){
    this.is_loading = true
    this.travailleurService.getTravailleurService().subscribe((data:any) => {
      data.forEach(element => {
          element.nom_prenom = `${element.nom_travailleur_cap}, ${element.prenom_travailleur_cap}`
      });
      this.travailleurs_liste = data
      this.is_loading = false
    })
  }

  getInfoTravailleur(travailleur:any){
    const resultat = this.travailleurs_liste.filter(t => t.id_personne == travailleur)
    // console.log(resultat)
    if(resultat.length != 0){
      this.info_individu.nom_travailleur = resultat[0].nom_travailleur_cap
      this.info_individu.prenom_travailleur = resultat[0].prenom_travailleur_cap
      this.info_individu.date_naissance = DateTime.fromISO(resultat[0].date_naissance).toFormat("yyyy-MM-dd")
      this.info_individu.sexe_travailleur = resultat[0].libelle_sexe
      this.info_individu.id_personne = resultat[0].id_personne
      this.info_individu.matricule = resultat[0].matricule
      this.info_individu.entrepreneur = resultat[0].nom_entrepreneur
      this.info_individu.date_emploi = resultat[0].date_emploi == null ? "" : DateTime.fromISO(resultat[0].date_emploi).toFormat("yyyy-MM-dd")
      this.info_individu.statut = resultat[0].statut_employe
      this.info_individu.code = resultat[0].code_organisation
      this.info_individu.departement = resultat[0].nom_departement
      this.info_individu.sous_departement = resultat[0].nom_sous_departement
      this.info_individu.equipe = resultat[0].equipes[0].nom_equipe
      this.info_individu.horaire = resultat[0].horaire
      this.info_individu.id_fonction = resultat[0].id_poste
      this.info_individu.fonction = resultat[0].nom_fonction
      this.info_individu.realite = resultat[0].realite ? "OUI" : "NON"
      this.info_individu.matricule_sup = resultat[0].matricule_sup_immediat
      this.info_individu.sup_imm = resultat[0].nom_sup_immediat + ", " + resultat[0].prenom_sup_immediat 
    }
  }

  resetForm(){
    this.info_individu = {
      nom_travailleur: "",
      prenom_travailleur: "",
      date_naissance: "",
      sexe_travailleur: "",
      id_personne: "",
      matricule: "",
      entrepreneur: "",
      date_emploi: "",
      statut: "",
      code: "",
      departement: "",
      sous_departement: "",
      equipe: "",
      horaire: "",
      id_fonction: "",
      fonction: "",
      realite: "",
      matricule_sup: "",
      sup_imm: "",
    }
    this.searchTravailleur = ""
  }

}
