<app-load-page *ngIf="is_loading"></app-load-page>
<div class="d-flex flex-row align-items-center justify-content-between">
    <div class="d-flex flex-row">
        <div>
            <div class="input-group input-group-sm mb-1">
                <label class="input-group-text" for="inputGroupSelect01">Département</label>
                <select class="form-select" id="inputGroupSelect01" [(ngModel)]="departement"
                    (change)="getData(departement)" [disabled]="is_loading || is_filter">
                    <ng-container *ngIf="is_filter">
                        <option></option>
                    </ng-container>
                    <ng-container *ngIf="!is_filter">
                        <option *ngFor="let item of liste_departement" style="font-size: 12px;" [value]="item.code_organisation">{{item.code_organisation}} - {{item.nom_departement}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div>
            <div class="input-group input-group-sm mb-1 ms-2">
                <label class="input-group-text" for="inputGroupSelect01">Equipe</label>
                <select class="form-select" id="inputGroupSelect01" [(ngModel)]="equipe" (change)="changeEquipe(equipe)"
                    [disabled]="is_loading" [disabled]="is_filter || is_loading">
                    <ng-container *ngIf="is_filter">
                        <option></option>
                    </ng-container>
                    <ng-container *ngIf="!is_filter">
                        <option *ngFor="let equipe of liste_equipe" [value]="equipe" style="font-size: 12px;">{{equipe}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between">
        <div>
            <div class="input-group input-group-sm">
                <span class="input-group-text" id="basic-addon1" (click)="reset_recherche()">
                    <i *ngIf="!is_filter || nom_prenom_search == ''" class="bi bi-search"></i>
                    <i *ngIf="is_filter && nom_prenom_search != ''" class="bi bi-x-lg"></i>
                </span>
                <input type="text" class="form-control" placeholder="Nom & prénom..." (change)="searchPersonne()"
                    [(ngModel)]="nom_prenom_search" [disabled]="matricule_search != ''" aria-label="Recherche..."
                    aria-describedby="basic-addon1">
            </div>
        </div>
        <div class="me-2 ms-2">
            <button mat-icon-button (click)="prev_year()" [disabled]="current_year == 2024 || is_loading"> <i
                    class="bi bi-chevron-left"></i> </button>
            <span class="me-2 ms-2">{{current_year}}</span>
            <button mat-icon-button (click)="next_year()" [disabled]="is_loading"> <i class="bi bi-chevron-right"></i>
            </button>
        </div>
        <div class="bg-white border rounded shadow-sm px-2 py-1 d-flex flex-row align-items-center" id="state_nombre">
            <span style="font-size: 12px;">Nb actuel : {{actuel_nb}}</span>
            <span class="ms-2 me-2" style="color: #707fdd; font-size: 12px;">|</span>
            <span style="font-size: 12px;">Total travailleur : {{total_nb}}</span>
        </div>
    </div>
</div>
<div *ngIf="!is_loading">
    <div style="overflow: auto; width: 100%; height: 87vh; margin-top: 5px;" class="rounded">
        <table class="table table-sm table-bordered table-hover">
            <thead class="sticky-row">
                <ng-container *ngFor="let year of annee_liste">
                    <tr class="mois-ligne">
                        <th colspan="3" class="sticky-column" style="width: 400px; background-color: #f8f8f8;"></th>
                        <ng-container *ngFor="let month of year.months">
                            <th [attr.colspan]="month.nombre_jour"
                                [ngStyle]="{'background-color': month.color, 'width': (month.nombre_jour * 25) + 'px'}">
                                {{month.mois}} {{month.annee}}</th>
                        </ng-container>
                    </tr>
                    <tr class="jour-ligne">
                        <th colspan="3" class="sticky-column" style="width: 400px; background-color: #f8f8f8;"></th>
                        <ng-container *ngFor="let month of year.months">
                            <th *ngFor="let jour of month.jours" style="background-color: rgb(249, 245, 221);">
                                {{jour.num}}
                            </th>
                        </ng-container>
                    </tr>
                    <tr class="jour-ligne">
                        <th colspan="3" class="sticky-column"
                            style="width: 400px; background-color: rgb(249, 245, 221);">
                            <div class="d-flex flex-row align-items-center justify-content-between px-2">
                                <div class="header-table">
                                    <span style="font-size: 12px;" (click)="trieParKey('horaire')">Hor. <i
                                            [class]="icon_trie" *ngIf="affiche_icon.horaire"></i></span>
                                    <span style="font-size: 12px;" class="ms-4"
                                        (click)="trieParKey('nom_travailleur')">Nom & prénom <i [class]="icon_trie"
                                            *ngIf="affiche_icon.nom_travailleur"></i></span>
                                </div>
                                <div class="header-table">
                                    <span style="font-size: 12px;" (click)="trieParKey('nom_equipe')">Equipe <i
                                            [class]="icon_trie" *ngIf="affiche_icon.nom_equipe"></i></span>
                                </div>
                            </div>
                        </th>
                        <ng-container *ngFor="let month of year.months">
                            <th *ngFor="let jour of month.jours" style="background-color: rgb(252, 250, 241);">
                                {{jour.libelle}}</th>
                        </ng-container>
                    </tr>
                </ng-container>
            </thead>
            <tbody>
                <tr *ngFor="let worker of travailleurs_dynamique; let i = index">
                    <td colspan="3" class="sticky-column">
                        <div class="d-flex flex-row align-items-center justify-content-between px-2"
                            ngbTooltip="{{worker.code_organisation}} - {{worker.nom_departement}}" placement="right"
                            *ngIf="is_filter">
                            <div>
                                <span>{{worker.horaire}}</span>
                                <span class="ms-4">{{worker.nom_prenom}}</span>
                            </div>
                            <span>{{worker.nom_equipe}}</span>
                        </div>
                        <div class="d-flex flex-row align-items-center justify-content-between px-2" *ngIf="!is_filter">
                            <div>
                                <span>{{worker.horaire}}</span>
                                <span class="ms-4">{{worker.nom_prenom}}</span>
                            </div>
                            <span>{{worker.nom_equipe}}</span>
                        </div>
                    </td>
                    <ng-container *ngFor="let item of worker.days">
                        <td [ngStyle]="{'background-color': item.valeur == '' ? '#f8f8f8' : '#ffffff'}">{{item.valeur}}
                        </td>
                    </ng-container>
                    <!-- <ng-container *ngFor="let year of annee_liste">
                        <ng-container *ngFor="let month of year.months">
                            <ng-container *ngFor="let jour of month.jours">
                                <td style="color: rgb(65, 65, 65);"
                                    [ngStyle]="{'background-color': getDataFromTravailleur(jour.date, i) == '' ? '#f8f8f8' : '#ffffff'}">
                                    {{getDataFromTravailleur(jour.date, i)}}</td>
                            </ng-container>
                        </ng-container>
                    </ng-container> -->
                </tr>
            </tbody>
        </table>
    </div>
</div>