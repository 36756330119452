<app-load-page *ngIf="is_loading"></app-load-page>
<div>
    <div class="py-1">
        <div class=" d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-row align-items-center bg-white rounded shadow-sm px-3 py-1">
                <ng-container *ngFor="let statut of statuts; let i = index">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div style="width: 15px; height: 15px;" class="rounded border me-2"
                            [ngStyle]="{'background-color':statut.color}"></div>
                        <span style="font-size: 13px;">{{statut.libelle}}</span>
                    </div>
                    <span *ngIf="statuts.length != i+1" style="font-size: 10px;" class="ms-3 me-3">|</span>
                </ng-container>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between">
                <div>
                    <button mat-icon-button (click)="prev_year()" [disabled]="current_year == 2024 || is_loading"> <i
                            class="bi bi-chevron-left"></i> </button>
                    <span class="me-2 ms-2">{{current_year}}</span>
                    <button mat-icon-button (click)="next_year()" [disabled]="is_loading"> <i
                            class="bi bi-chevron-right"></i></button>
                </div>
                <div class="ms-2" *ngIf="access">
                    <button class="btn-special" data-bs-toggle="modal" data-bs-target="#planification" [disabled]="is_loading"> <i
                            class="bi bi-calendar-event me-1" style="font-size: 12px;"></i> Planification des activités</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Planification -->
    <div class="modal fade" id="planification" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body rounded" style="background-color: #f8f8f8;">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <h4>Planification</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mt-2">
                        <div class="row">
                            <div class="col-6">
                                <div class="input-group input-group-sm mb-2">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Date Début *</span>
                                    <input type="date" class="form-control" aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-sm" [(ngModel)]="form_planification.date_debut" [ngClass]="{'is-invalid':form_planification.date_debut == '' && is_verifing}">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="input-group input-group-sm mb-2">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Date Fin *</span>
                                    <input type="date" class="form-control" aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-sm" [min]="form_planification.date_debut" [(ngModel)]="form_planification.date_fin" [ngClass]="{'is-invalid':form_planification.date_fin == '' && is_verifing}">
                                </div>
                            </div>
                            <div class="col-7">
                                <div class="input-group input-group-sm mb-2">
                                    <label class="input-group-text" for="inputGroupSelect01">Formateur *</label>
                                    <select class="form-select" id="inputGroupSelect01" [(ngModel)]="form_planification.formateur_selected" [ngClass]="{'is-invalid':form_planification.formateur_selected == '' && is_verifing}">
                                        <option selected disabled value=""></option>
                                        <option *ngFor="let formateur of formateurs" [value]="formateur.id_formateur">{{formateur.nom_formateur | capitalizeFirstLetter}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="input-group input-group-sm mb-2">
                                    <label class="input-group-text" for="inputGroupSelect01">Activité *</label>
                                    <select class="form-select" id="inputGroupSelect01" [(ngModel)]="form_planification.activite" [ngClass]="{'is-invalid':form_planification.activite == '' && is_verifing}">
                                        <option selected disabled value=""></option>
                                        <option *ngFor="let statut of statuts" [value]="statut.value">{{statut.libelle}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <button class="btn btn-sm" style="background-color: rgba(0, 128, 0, 0.229);" (click)="savePlanification()">Enregistrer</button>
                            <button class="btn btn-sm ms-1" style="background-color: lightgray;" (click)="resetFormPlanification()">Réinitialiser</button>
                            <span style="font-size: 11px;" class="text-secondary ms-2">(*) Champs obligatoire</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="overflow: auto; width: 100%; margin-top: 5px;" *ngIf="!is_loading">
        <table class="table table-sm table-bordered table-hover">
            <thead class="sticky-row">
                <ng-container *ngFor="let year of annee_liste">
                    <tr class="mois-ligne">
                        <th colspan="2" class="sticky-column" style="width: 250px; background-color: #f8f8f8;"></th>
                        <ng-container *ngFor="let month of year.months">
                            <th [attr.colspan]="month.nombre_jour"
                                [ngStyle]="{'background-color': month.color, 'width': (month.nombre_jour * 25) + 'px'}">
                                {{month.mois}} {{month.annee}}</th>
                        </ng-container>
                    </tr>
                    <tr class="jour-ligne">
                        <th colspan="2" class="sticky-column" style="width: 250px; background-color: #f8f8f8;"></th>
                        <ng-container *ngFor="let month of year.months">
                            <th *ngFor="let jour of month.jours" style="background-color: rgb(249, 245, 221);">
                                {{jour.num}}
                            </th>
                        </ng-container>
                    </tr>
                    <tr class="jour-ligne">
                        <th colspan="2" class="sticky-column"
                            style="width: 250px; background-color: rgb(249, 245, 221);">
                            <div class="d-flex flex-row align-items-center justify-content-between px-2">
                                <div class="header-table">
                                    <span style="font-size: 12px;">Hor.</span>
                                    <span style="font-size: 12px;" class="ms-4">Nom & prénom</span>
                                </div>
                            </div>
                        </th>
                        <ng-container *ngFor="let month of year.months">
                            <th *ngFor="let jour of month.jours" style="background-color: rgb(252, 250, 241);">
                                {{jour.libelle}}</th>
                        </ng-container>
                    </tr>
                </ng-container>
            </thead>
            <tbody>
                <tr *ngFor="let worker of formateurs; let index_formateur = index">
                    <td colspan="2" class="sticky-column">
                        <div class="d-flex flex-row align-items-center justify-content-between px-2">
                            <div>
                                <span>{{worker.horaire}}</span>
                                <span class="ms-4">{{worker.nom_formateur | capitalizeFirstLetter}}</span>
                            </div>
                        </div>
                    </td>
                    <ng-container *ngFor="let item of worker.days; let index_days = index">
                        <td [ngStyle]="{'background-color': item.color}">
                            <div *ngIf="item.valeur != ''">
                                <button class="btn-calendrier" [disabled]="is_editing"
                                    (click)="changeStateDays(index_formateur, index_days)">{{item.valeur}}</button>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>