import { Component, OnInit } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import {DateTime} from 'luxon';
import { saveAs } from 'file-saver';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-tableau-formation',
  templateUrl: './tableau-formation.component.html',
  styleUrls: ['./tableau-formation.component.css']
})
export class TableauFormationComponent implements OnInit {

  dataTableauGlobal: any
  dataTableau: any
  dataTableau_dynamique: any
  dataTableau_temp: any
  formation_search: any = ""
  data_calendrier: any
  is_loading: any = false
  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    num_demande: false,
    demandeur: false,
    date_prevue: false,
    statut_inscription: false,
    num_formation: false,
    nom_formation: false,
    nom_formateur: false,
    travailleur: false,
  }
  is_downloading:any = false
  
  access:any = false
  role_access:any = [99,10]
  is_admin:any = false
  user_log:any = []
  getUser(){
    this.userService.getUserService({}).subscribe((data:any) => {
      this.user_log = data
      this.is_admin = this.role_access.includes(this.user_log.role)
    })
  }

  constructor(
    private calendrierService: CalendrierService,
    private toast: ToastService,
    private userService: UsersService
  ) {
    this.getUser()
    this.getDataTableauGlobal()
  }

  ngOnInit(): void { }

  searchFormation(formation: any) {
    this.dataTableau_dynamique = this.dataTableau.filter(dt => dt.nom_formation.toLowerCase().includes(formation.toLowerCase()))
  }

  resetSearch() {
    this.dataTableau_dynamique = this.dataTableau
    this.formation_search = ""
  }

  trierParTravailleurs(data) {
    return data.sort((a, b) => b.travailleurs.length - a.travailleurs.length);
  }

  getDataTableauGlobal() {
    this.is_loading = true
    this.calendrierService.getTableauGlobalService().subscribe((data: any) => {
      this.dataTableauGlobal = this.trierParTravailleurs(data[0])
      this.dataTableau = data[1]
      this.dataTableau_dynamique = [...this.dataTableau]
      this.dataTableau_temp = [...this.dataTableau]
      this.dataTableauGlobal.forEach(element => {
        if (element.travailleurs) {
          element.color = this.getRandomColor()
          element.travailleurs.sort((a, b) => new Date(b.date_prevue).getTime() - new Date(a.date_prevue).getTime())
          element.travailleurs.forEach(travailleur => {
            const currentDate = new Date();
            const datePrevue = new Date(travailleur.date_prevue);
            currentDate.setHours(0, 0, 0, 0);
            datePrevue.setHours(0, 0, 0, 0);
            travailleur.show = datePrevue >= currentDate;
          });
        }
      });
      this.is_loading = false
      console.log(this.dataTableau)
    })
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.2;
    return `rgba(${r},${g},${b},${a})`;
  }

  trieParKey(key: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    if (this.type_switch == 1) {
      this.dataTableau_dynamique = key.includes("date") ? this.dataTableau_temp.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()) : this.dataTableau_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.dataTableau_dynamique = key.includes("date") ? this.dataTableau_temp.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()) : this.dataTableau_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.dataTableau_dynamique = [...this.dataTableau]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }

  deleteFormation(data: any, index: any, global: any = false) {
    var dataObj = { id_travailleur_formation: data[index].id_travailleur_formation, type: 1 }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer ce travailleur de cette formation ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.deleteTravailleurFormationService(dataObj).subscribe(() => {
          this.toast.Success("Travailleur supprimé du formation")
          if (global == true) {
            data.splice(index, 1)
          } else {
            this.dataTableau_dynamique.splice(index, 1)
          }
        })
      }
    });
  }

  exporterTableau() {
    this.is_downloading = true
    const current_date = DateTime.fromJSDate(new Date()).setZone('UTC').toFormat("yyyy-MM-dd")
    this.calendrierService.exportFormationService().subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = `${current_date} Tableau détaillé des formations`
      saveAs(file, filename);
      this.is_downloading = false
    })
  }
}
