<div class="px-2">
  <div class="calendar-container py-1">
    <div class="calendar-header shadow-sm d-flex flex-row align-items-center justify-content-between">
      <div style="margin-top: 5px;">
        <h5 *ngIf="currentView === 'month' || currentView === 'week'">{{ viewDate | date : "MMMM" |capitalizeFirstLetter}} {{viewDate | date: "y"}} <span class="text-secondary" style="font-size: 12px;">({{nombre_formation_mois}})</span> </h5>
      </div>
      <div class="calendar-controls d-flex flex-row align-items-center">
        <div style="margin-right: 35px;">
          <div class="input-group input-group-sm">
            <label class="input-group-text" for="inputGroupSelect01"> <i [ngClass]="{'bi bi-funnel-fill me-1 text-special':formateur_filtered != ''}" style="font-size: 12px;"></i> Formateurs</label>
            <select class="form-select" id="inputGroupSelect01" [(ngModel)]="formateur_filtered"
              (change)="filteredByFormateur(formateur_filtered)">
              <option selected value="">Tous</option>
              <option *ngFor="let formateur of liste_formateur" [value]="formateur.initial">{{formateur.nom}}</option>
            </select>
          </div>
        </div>
        <div>
          <ul class="nav nav-underline">
            <li class="nav-item">
              <a class="nav-link" style="font-size: 13px;" [ngClass]="{'active':currentView == 'month'}"
                aria-current="page" (click)="switchView(CalendarView.Month)">Mois</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="font-size: 13px;" [ngClass]="{'active':currentView == 'week'}"
                (click)="switchView(CalendarView.Week)">Semaine</a>
            </li>
          </ul>
        </div>
        <div class="ms-3">
          <button [disabled]="is_loading" mat-icon-button (click)="previous()"><i
              class="bi bi-chevron-left"></i></button>
          <button [disabled]="is_loading" class="btn" (click)="viewToday()">Aujourd'hui</button>
          <button [disabled]="is_loading" mat-icon-button (click)="next()"><i class="bi bi-chevron-right"></i></button>
        </div>
      </div>
    </div>

    <app-load-page *ngIf="is_loading"></app-load-page>
    <div *ngIf="!is_loading" style="overflow: auto; height: 85vh; margin-top: 8px; width: 100%;">
      <table width="100%" cellspacing="0" cellpadding="0" class="calendar-view-month" *ngIf="currentView === 'month'">
        <thead>
          <tr style="position: sticky; top:0; background: none;">
            <th *ngFor="let day of weekDays">
              {{ day }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngFor="let week of weeks">
            <td *ngFor="let date of week" [class.today]="isToday(date)"
              [ngClass]="{'notCurrentMonthStyle' : !isCurrentMonth(date)}">
              <div class="cell-overlay" tabindex="0"></div>
              <div class="date">{{ date.getDate() }}</div>
              <div class="appointments">
                <ng-container *ngFor="let item of formations_dynamique">
                  <div *ngIf="isSameDate(item.date_prevue, date)" [class]="item.class" (click)="openModal(item)"
                    [ngStyle]="{ 'background-color': item.statut_demande == 0 ? item.color : '' }">

                    <!-- Icône positionnée en haut à gauche -->
                    <span style="position: absolute; top: 1px; left: 3px;" *ngIf="item.statut_inscription == 'complétée'">
                      <i class="bi bi-check-circle-fill text-success" style="font-size: 11px;"></i>
                    </span>

                    <span style="position: absolute; top: 1px; left: 3px;" *ngIf="item.statut_inscription == 'en progression'">
                      <i class="bi bi-dash-circle-fill text-warning" style="font-size: 11px;"></i>
                    </span>

                    <div *ngIf="item.class == 'open_inscription'" style="margin-bottom: -4px;">
                      <span style="color: white;" class="span-inscription"> <i class="bi bi-pencil-square me-1"
                          style="font-size: 9px;"></i> Inscription ouverte</span>
                    </div>
                    <div style="margin-bottom: -6px;">
                      <span style="font-size: 10px; color: rgb(51, 51, 51);"
                        class="fw-bold">{{item.initial_formateur}}</span>
                      <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{item.heure_debut}} -
                        {{item.heure_fin}}</span>
                    </div>
                    <span class="span-formation">{{ item.nom_formation }}</span>
                    <div>
                      <ul>
                        <li *ngFor="let worker of item.travailleurs">{{worker.nom_complet}}</li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>


      <table width="100%" cellspacing="0" cellpadding="0" class="calendar-view-week" *ngIf="currentView === 'week'">
        <thead>
          <tr style="position: sticky; top:0; background: none;">
            <th></th>
            <th *ngFor="let day of weekDays; index as i" style="font-size: 18px;">
              {{ day }} {{ monthDays[i].getDate() }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngFor="let timeSlot of timeSlots">
            <td [width]="10" class="calendar-slot-cell" style="background-color: #f8f8f8;">
              <span class="border rounded shadow-sm px-3">{{ timeSlot }}</span>
            </td>
            <td *ngFor="let day of weekDays; index as i">
              <div tabindex="0" *ngFor="let item of getAppointmentsForDateTime(monthDays[i],timeSlot)"
                class="close_inscription_week" [ngStyle]="{'background-color': item.color }">
                <div style="margin-bottom: -8px;"><span
                    style="font-size: 10px; color: rgb(51, 51, 51);">{{item.initial_formateur}} -
                    {{item.num_formation}}</span></div>
                <span>{{ item.nom_formation }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>