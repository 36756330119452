<app-load-page *ngIf="is_loading"></app-load-page>
<div class="p-3" *ngIf="!is_loading">
    <div>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <div style="width: 20%;">
                <h4>Information personnel</h4>
            </div>
            <hr style="width: 75%; padding: 1px;" class="bg-primary rounded me-4">
            <div style="width:25%;">
                <div class="input-group input-group-sm">
                    <span class="input-group-text" id="basic-addon1"> <i class="bi bi-x-lg me-2" *ngIf="searchTravailleur != ''" (click)="resetForm()"></i> Travailleurs</span>
                    <input type="text" class="form-control" [(ngModel)]="searchTravailleur" (change)="getInfoTravailleur(searchTravailleur)" id="basic-addon1" list="datalistOptions" aria-label="Username" aria-describedby="basic-addon1">
                    <datalist id="datalistOptions">
                        <option  *ngFor="let worker of travailleurs_liste" value="{{worker.id_personne}}">{{worker.nom_prenom}}</option>
                    </datalist>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div class="row">
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" [value]="info_individu.nom_travailleur" id="nom" placeholder="name@example.com" readonly>
                        <label for="nom">Nom du travailleur</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" [value]="info_individu.prenom_travailleur" id="prenom" placeholder="name@example.com" readonly>
                        <label for="prenom">Prénom du travailleur</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" [value]="info_individu.date_naissance" id="ddnais" placeholder="name@example.com" readonly>
                        <label for="ddnais">Date de naissance</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" [value]="info_individu.sexe_travailleur | capitalizeFirstLetter" id="sexe" placeholder="name@example.com" readonly>
                        <label for="sexe">Sexe du travailleur</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4">
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h4>Profil travailleur</h4>
            <hr style="width: 87%; padding: 1px;" class="bg-warning rounded">
        </div>
        <div class="mt-3">
            <div class="row">
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.id_personne" class="form-control" id="id_perso" placeholder="name@example.com" readonly>
                        <label for="id_perso">ID Personne</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.matricule" class="form-control" id="matricule" placeholder="name@example.com" readonly>
                        <label for="matricule">Matricule</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.entrepreneur" class="form-control" id="Entrepreneur" placeholder="name@example.com" readonly>
                        <label for="Entrepreneur">Entrepreneur</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.date_emploi" class="form-control" id="Date_emploi" placeholder="name@example.com" readonly>
                        <label for="Date_emploi">Date d'emploi</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.statut | capitalizeFirstLetter" class="form-control" id="Statut" placeholder="name@example.com" readonly>
                        <label for="Statut">Statut</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.code" class="form-control" id="Code" placeholder="name@example.com" readonly>
                        <label for="Code">Code organisation</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.departement" class="form-control" id="Département" placeholder="name@example.com" readonly>
                        <label for="Département">Département</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.sous_departement | capitalizeFirstLetter" class="form-control" id="Sous-départemen" placeholder="name@example.com" readonly>
                        <label for="Sous-départemen">Sous département</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.equipe" class="form-control" id="Equipe" placeholder="name@example.com" readonly>
                        <label for="Equipe">Equipe</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.horaire" class="form-control" id="Horaire" placeholder="name@example.com" readonly>
                        <label for="Horaire">Horaire</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.id_fonction" class="form-control" id="IDFonction" placeholder="name@example.com" readonly>
                        <label for="IDFonction">ID Fonction</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.fonction | capitalizeFirstLetter" class="form-control" id="Fonction" placeholder="name@example.com" readonly>
                        <label for="Fonction">Fonction</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.realite" class="form-control" id="Réalité" placeholder="name@example.com" readonly>
                        <label for="Réalité">Réalité</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.matricule_sup" class="form-control" id="MSUPIM" placeholder="name@example.com" readonly>
                        <label for="MSUPIM">Matricule Sup.Imm</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" [value]="info_individu.sup_imm" class="form-control" id="Supérieur" placeholder="name@example.com" readonly>
                        <label for="Supérieur">Supérieur immédiat</label>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="Niveau" placeholder="name@example.com" readonly>
                        <label for="Niveau">Niveau supérieur immédiat</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>