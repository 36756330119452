import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as go from 'gojs';
import { TravailleurService } from '../services/travailleur/travailleur.service';
import { UsersService } from '../services/users/users.service';

const $ = go.GraphObject.make;

@Component({
  selector: 'app-ressource',
  templateUrl: './ressource.component.html',
  styleUrls: ['./ressource.component.css']
})

export class RessourceComponent {

  constructor(
    private travailleurService: TravailleurService
  ) {
    this.getAllDepartement()
    this.getOrganigrammeData(this.departement)
  }

  public selectedNode = null;
  public model: go.TreeModel
  public setSelectedNode(node) {
    this.selectedNode = node;
  }
  searchText: any
  is_loading: any = false
  departement: any = 1
  departement_liste: any

  public diagram: go.Diagram = null;

  getAllDepartement() {
    this.travailleurService.getDataDropDownService().subscribe((data: any) => {
      this.departement_liste = data[2]
    })
  }

  getOrganigrammeData(departement: any) {
    this.is_loading = true
    this.disposeDiagram()
    var dataObj = { id_departement: parseInt(departement) }
    this.travailleurService.getOrganigrammeService(dataObj).subscribe((data: any) => {
      this.model = new go.TreeModel(data)
      this.setOrganigramme(this.model)
    })
  }

  setOrganigramme(model: any) {
    this.diagram = $(go.Diagram, 'myDiagramDiv', {
      layout: $(go.TreeLayout, {
        isOngoing: true,
        treeStyle: go.TreeLayout.StyleLastParents,
        arrangement: go.TreeLayout.ArrangementHorizontal,
        angle: 90,
        layerSpacing: 50,
        nodeSpacing:100,
        alternateAngle: 90,
        alternateLayerSpacing: 35,
        alternateAlignment: go.TreeLayout.AlignmentBusBranching,
        alternateNodeSpacing: 20
      }),
      'undoManager.isEnabled': true,

      allowZoom: true,
      'toolManager.mouseWheelBehavior': go.ToolManager.WheelZoom,
      'maxScale': 3.5,
      'minScale': 0.4,
    });

    this.diagram.linkTemplate =
      new go.Link({ routing: go.Routing.Orthogonal, corner: 5 })
        .add(new go.Shape({ strokeWidth: 3, stroke: "#555" }));

    this.diagram.nodeTemplate =
      $(go.Node, 'Auto',
        new go.Binding('text', 'nom_travailleur'),
        new go.Binding('layerName', 'isSelected', function (sel) { return sel ? 'Foreground' : ''; }).ofObject(),
        $(go.Shape, 'RoundedRectangle', {
          name: 'SHAPE', fill: 'lightblue', stroke: null,
          portId: '', fromLinkable: true, toLinkable: true, cursor: 'pointer'
        },
          new go.Binding('fill', '', function (node) {
            const levelColors = ['#AC193D', '#2672EC', '#8C0095', '#5133AB', '#008299', '#8a8363', '#787878', '#094AB2'];
            let color = node.findObject('SHAPE').fill;
            const dia: go.Diagram = node.diagram;
            if (dia && dia.layout.network) {
              dia.layout.network.vertexes.each(function (v: go.TreeVertex) {
                if (v.node && v.node.key === node.data.key) {
                  const level: number = v.level % (levelColors.length);
                  color = levelColors[level];
                }
              });
            }
            return color;
          }).ofObject()
        ),
        $(go.Panel, 'Horizontal',
          $(go.Panel, 'Table', {
            maxSize: new go.Size(300, 999),
            margin: new go.Margin(5, 5, 5, 5),
            defaultAlignment: go.Spot.Left
          },
            
          // NOM PRENOM
            $(go.RowColumnDefinition, { column: 2, width: 4 }),
            $(go.TextBlock, { stroke: 'white' }, {
              row: 0, column: 0, columnSpan: 5,
              font: '11pt Poppins,sans-serif',
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 16),
              alignment: go.Spot.Center
            }, new go.Binding('text', '', function (data) {
              return data.prenom_travailleur + ' ' + data.nom_travailleur;
            }).makeTwoWay()),

            // SOUS DEPARTEMENT
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 1, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'sous_departement', function(sous_departement) {
              return sous_departement && sous_departement.trim() != "" ? sous_departement : "-- VIDE --"
            }).makeTwoWay(),
              new go.Binding('visible', 'sup_niveau', function (niveau) {
                return parseInt(niveau) >= 1 && parseInt(niveau) <= 3;
              })
            ),

            // NOM EMPLOI
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 2, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'nom_emploi', function(cat_emploi) {
              return cat_emploi && cat_emploi.trim() != '' ? cat_emploi : '-- VIDE --';
            }).makeTwoWay()),

            // HORAIRE
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 3, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'horaire', function(horaire) {
              return horaire && horaire.trim() != '' ? horaire : '-- VIDE --'
            }).makeTwoWay(),
              new go.Binding('visible', 'sup_niveau', function (niveau) {
                return parseInt(niveau) < 3;
              })
            ),

            // EQUIPE
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 4, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'nom_equipe', function (nom_equipe) {
              return nom_equipe && nom_equipe.trim() != '' ? nom_equipe : '-- VIDE --';
            }).makeTwoWay(),
              new go.Binding('visible', 'sup_niveau', function (niveau) {
                return parseInt(niveau) < 3;
              })
            ),
          )
        )
      );
    this.diagram.model = model;
    this.is_loading = false
  }

  clearDiagram() {
    if (this.diagram) {
      this.diagram.clear();
    }
  }

  disposeDiagram() {
    if (this.diagram) {
      this.diagram.div = null;
      this.diagram = null;
      this.clearDiagram()
    }
  }

  zoomToFit() {
    if (this.diagram) {
      this.diagram.commandHandler.zoomToFit();
    }
  }

  zoomIn() {
    const currentScale = this.diagram.scale;
    this.diagram.scale = currentScale * 1.2;
  }

  zoomOut() {
    const currentScale = this.diagram.scale;
    this.diagram.scale = currentScale / 1.2;
  }

}