import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as go from 'gojs';

const $ = go.GraphObject.make;

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.css']
})
export class OrgChartComponent {

  public diagram: go.Diagram = null;

  @Input()
  public model: go.Model;

  @Output()
  public nodeClicked = new EventEmitter();

  ngAfterViewInit(): void {
    this.diagram = $(go.Diagram, 'myDiagramDiv',
      {
        layout:
          $(go.TreeLayout,
            {
              isOngoing: true,
              treeStyle: go.TreeLayout.StyleLastParents,
              arrangement: go.TreeLayout.ArrangementHorizontal,

              angle: 90,
              layerSpacing: 35,

              alternateAngle: 90,
              alternateLayerSpacing: 35,
              alternateAlignment: go.TreeLayout.AlignmentBus,
              alternateNodeSpacing: 20
            }),
        'undoManager.isEnabled': true
      }
    );

    this.diagram.linkTemplate =
      new go.Link(
        { routing: go.Routing.Orthogonal, corner: 5 })
        .add(
          new go.Shape({ strokeWidth: 3, stroke: "#555" })
        );

    this.diagram.nodeTemplate =
      $(go.Node, 'Auto',
        new go.Binding('text', 'nom_travailleur'),
        new go.Binding('layerName', 'isSelected', function (sel) { return sel ? 'Foreground' : ''; }).ofObject(),
        $(go.Shape, 'RoundedRectangle',
          {
            name: 'SHAPE', fill: 'lightblue', stroke: null,
            portId: '', fromLinkable: true, toLinkable: true, cursor: 'pointer'
          },
          new go.Binding('fill', '', function (node) {
            const levelColors = ['#AC193D', '#2672EC', '#8C0095', '#5133AB', '#008299', '#D24726', '#008A00', '#094AB2'];
            let color = node.findObject('SHAPE').fill;
            const dia: go.Diagram = node.diagram;
            if (dia && dia.layout.network) {
              dia.layout.network.vertexes.each(function (v: go.TreeVertex) {
                if (v.node && v.node.key === node.data.key) {
                  const level: number = v.level % (levelColors.length);
                  color = levelColors[level];
                }
              });
            }
            return color;
          }).ofObject()
        ),
        $(go.Panel, 'Horizontal',
          // $(go.Picture,
          //   {
          //     name: 'Picture',
          //     desiredSize: new go.Size(39, 50),
          //     margin: new go.Margin(6, 8, 6, 10)
          //   },
          //   new go.Binding('source', 'key', function(key) {
          //     if (key < 0 || key > 16) return '';
          //     return 'assets/HS' + key + '.png';
          //   })
          // ),

          $(go.Panel, 'Table',
            {
              maxSize: new go.Size(150, 999),
              margin: new go.Margin(5, 5, 5, 5),
              defaultAlignment: go.Spot.Left
            },
            $(go.RowColumnDefinition, { column: 2, width: 4 }),

            $(go.TextBlock, { stroke: 'white' },
              {
                row: 0, column: 0, columnSpan: 5,
                font: '10pt Poppins,sans-serif',
                editable: true, isMultiline: false,
                minSize: new go.Size(10, 16),
                alignment: go.Spot.Center
              },
              new go.Binding('text', 'name').makeTwoWay()),

            // $(go.TextBlock, { stroke: 'white' },
            //   { row: 1, column: 0 }),

            $(go.TextBlock, { font: '9pt Poppins,sans-serif', stroke: 'white' },
              {
                row: 1, column: 1, columnSpan: 4,
                editable: true, isMultiline: false,
                minSize: new go.Size(10, 14),
                margin: new go.Margin(0, 0, 0, 0),
                alignment: go.Spot.Center
              },
              new go.Binding('text', 'title').makeTwoWay()),

            // $(go.TextBlock,
            //   { stroke: 'white' },
            //   { row: 2, column: 0 },
            //   new go.Binding('text', 'key', function (v) { return 'ID: ' + v; })
            // ),

            // $(go.TextBlock,
            //   { stroke: 'white' },
            //   { name: 'boss', row: 2, column: 3 },
            //   new go.Binding('text', 'parent', function (v) { return 'Boss: ' + v; })
            // ),

            // $(go.TextBlock, { stroke: 'white' },
            //   {
            //     row: 3, column: 0, columnSpan: 5,
            //     // font: 'italic 9pt sans-serif',
            //     wrap: go.TextBlock.WrapFit,
            //     editable: true,
            //     minSize: new go.Size(10, 14)
            //   },
            //   new go.Binding('text', 'comments').makeTwoWay())
          )
        )
      );

    this.diagram.model = this.model;


    this.diagram.addDiagramListener('ChangedSelection', (e) => {
      const node = this.diagram.selection.first();
      this.nodeClicked.emit(node);
    });
  }
}
