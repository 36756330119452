<div class="p-3">
    <div class="d-flex flex-row align-items-center justify-content-between mb-4">
        <div class="d-flex flex-row align-items-center">
            <div>
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Matricule</span>
                    <input type="text" class="form-control" [(ngModel)]="matricule_search" (change)="getFullnameByMatricule()" [ngClass]="{'is-invalid':matricule_search == '' && is_verifing}">
                    <div class="input-group-append" *ngIf="matricule_search != ''">
                        <i class="bi bi-x-lg" style="font-size: 12px; cursor: pointer;" (click)="resetFormSearch()"></i>
                    </div>
                </div>
            </div>
            <div class="ms-2">
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Nom</span>
                    <input type="text" class="form-control" list="nom" [(ngModel)]="nom_search" (change)="getFullname()" [ngClass]="{'is-invalid':nom_search == '' && is_verifing}">
                    <div class="input-group-append" *ngIf="nom_search != ''">
                        <i class="bi bi-x-lg" style="font-size: 12px; cursor: pointer;" (click)="nom_search = ''; getFullname()"></i>
                    </div>
                </div>
                <datalist id="nom">
                    <option *ngFor="let nom of nom_travailleur_distinct" [value]="nom"></option>
                </datalist>
            </div>
            <div class="ms-2">
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Prénom</span>
                    <input type="text" class="form-control" list="prenom" [(ngModel)]="prenom_search" (change)="getFullname()" [ngClass]="{'is-invalid':prenom_search == '' && is_verifing}">
                    <div class="input-group-append" *ngIf="prenom_search != ''">
                        <i class="bi bi-x-lg" style="font-size: 12px; cursor: pointer;" (click)="prenom_search = ''; getFullname()"></i>
                    </div>
                </div>
                <datalist id="prenom">
                    <option *ngFor="let prenom of prenom_travailleur_distinct" [value]="prenom">
                </datalist>
            </div>
        </div>
        <div>
            <button class="btn-special ms-3" (click)="afficheData()"> <i class="bi bi-search me-1" style="font-size: 12px;"></i> Afficher les données</button>
            <button class="btn-special ms-1" (click)="resetFormSearch()"> <i class="bi bi-arrow-clockwise me-1" style="font-size: 12px;"></i> Réinitialiser</button>
        </div>
    </div>
    <hr style="background-color: #707fdd; padding: 2px;">
    <div class="mt-4">
        <div class="row">
            <div class="col-12">
                <div class="p-3 mb-4 bg-white shadow-sm border rounded">
                    <div class="d-flex flex-row align-items-center">
                        <h5>Calendrier du travailleur</h5> 
                        <div class="spinner-border spinner-border-sm ms-3" role="status" *ngIf="loading_calendrier"></div>
                    </div>
                    <div class="mt-2">
                        <div style="overflow: auto;">
                            <table class="table table-sm table-bordered table-hover">
                                <thead class="sticky-row">
                                    <ng-container *ngFor="let year of dateData">
                                        <tr class="mois-ligne">
                                            <!-- <th colspan="3" class="sticky-column" style="width: 400px; background-color: #f8f8f8;"></th> -->
                                            <ng-container *ngFor="let month of year.months">
                                                <th [attr.colspan]="month.nombre_jour"
                                                    [ngStyle]="{'background-color': month.color, 'width': (month.nombre_jour * 25) + 'px'}">
                                                    {{month.mois}} {{month.annee}}</th>
                                            </ng-container>
                                        </tr>
                                        <tr class="jour-ligne">
                                            <!-- <th colspan="3" class="sticky-column" style="width: 400px; background-color: #f8f8f8;"></th> -->
                                            <ng-container *ngFor="let month of year.months">
                                                <th *ngFor="let jour of month.jours" style="background-color: rgb(249, 245, 221);">
                                                    {{jour.num}}
                                                </th>
                                            </ng-container>
                                        </tr>
                                        <tr class="jour-ligne">
                                            <!-- <th colspan="3" class="sticky-column"
                                                style="width: 400px; background-color: rgb(249, 245, 221);">
                                                <div class="d-flex flex-row align-items-center justify-content-between px-2">
                                                    <div class="header-table">
                                                        <span style="font-size: 12px;" (click)="trieParKey('horaire')">Hor.</span>
                                                        <span style="font-size: 12px;" class="ms-4"
                                                            (click)="trieParKey('nom_travailleur')">Nom & prénom</span>
                                                    </div>
                                                    <div class="header-table">
                                                        <span style="font-size: 12px;" (click)="trieParKey('nom_equipe')">Equipe</span>
                                                    </div>
                                                </div>
                                            </th> -->
                                            <ng-container *ngFor="let month of year.months">
                                                <th *ngFor="let jour of month.jours" style="background-color: rgb(252, 250, 241);">
                                                    {{jour.libelle}}</th>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let worker of travailleur_calendrier; let i = index">
                                        <!-- <td colspan="3" class="sticky-column">
                                            <div class="d-flex flex-row align-items-center justify-content-between px-2"
                                                ngbTooltip="{{worker.code_organisation}} - {{worker.nom_departement}}" placement="right"
                                                *ngIf="is_filter">
                                                <div>
                                                    <span>{{worker.horaire}}</span>
                                                    <span class="ms-4">{{worker.nom_prenom}}</span>
                                                </div>
                                                <span>{{worker.nom_equipe}}</span>
                                            </div>
                                            <div class="d-flex flex-row align-items-center justify-content-between px-2" *ngIf="!is_filter">
                                                <div>
                                                    <span>{{worker.horaire}}</span>
                                                    <span class="ms-4">{{worker.nom_prenom}}</span>
                                                </div>
                                                <span>{{worker.nom_equipe}}</span>
                                            </div>
                                        </td> -->
                                        <ng-container *ngFor="let item of worker.days">
                                            <td [ngStyle]="{'background-color': item.valeur == '' ? '#f8f8f8' : '#ffffff'}">{{item.valeur}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="mb-3 p-3 bg-white shadow-sm border rounded">
                    <div class="d-flex flex-row">
                        <h5>Profil du travailleur</h5>
                        <div class="spinner-border spinner-border-sm ms-3 mt-1" role="status" *ngIf="loading_data"></div>
                    </div>
                    <div class="mt-3 d-flex flex-row align-items-start">
                        <div class="card" style="width: 22rem;">
                            <div class="card-header"> INFO BASE </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Nom & prénom :</span> {{profil_travailleur.nom_prenom}} </li>
                                <li class="list-group-item"> <span>Sexe :</span> {{profil_travailleur.sexe | capitalizeFirstLetter}} </li>
                                <li class="list-group-item"> <span>Date naissance :</span> {{profil_travailleur.date_naissance}} </li>
                            </ul>
                        </div>
                        <div class="card ms-2" style="width: 22rem;">
                            <div class="card-header"> INFO TRAVAILLEUR </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Statut :</span> {{profil_travailleur.statut | capitalizeFirstLetter}} </li>
                                <li class="list-group-item"> <span>Matricule :</span> {{profil_travailleur.matricule}}  </li>
                                <li class="list-group-item"> <span>ID Personne :</span> {{profil_travailleur.id_personne}} </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2 d-flex flex-row align-items-start">
                        <div class="card" style="width: 22rem;">
                            <div class="card-header"> INFO EMPLOI </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Date début :</span> {{profil_travailleur.date_debut}} </li>
                                <li class="list-group-item"> <span>Date fin :</span> {{profil_travailleur.date_fin}} </li>
                                <li class="list-group-item"> <span>Type : </span> {{profil_travailleur.type_emploi | capitalizeFirstLetter}} </li>
                                <li class="list-group-item"> <span> Catégorie :</span> {{profil_travailleur.categorie | capitalizeFirstLetter}} </li>
                            </ul>
                        </div>
                        <div class="card ms-2" style="width: 22rem;">
                            <div class="card-header"> INFO AFFILIATION </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Département :</span> {{profil_travailleur.departement}} </li>
                                <li class="list-group-item"> <span>Sous département :</span> {{profil_travailleur.sous_departement}} </li>
                                <li class="list-group-item"> <span>Sup. Immédiat :</span> {{profil_travailleur.sup_immediat}} </li>
                                <li class="list-group-item"> <span>Superviseur :</span> {{profil_travailleur.superviseur}} </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2 d-flex flex-row align-items-start">
                        <div class="card" style="width: 22rem;">
                            <div class="card-header"> AUTRE INFO </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Entrepreneur : </span> {{profil_travailleur.entrepreneur}} </li>
                                <li class="list-group-item"> <span>Sauveteur :</span> {{profil_travailleur.sauveteur}} </li>
                                <li class="list-group-item"> <span>Pompier :</span> {{profil_travailleur.pompier}} </li>
                                <li class="list-group-item"> <span>Réalité :</span> {{profil_travailleur.realite}} </li>
                            </ul>
                        </div>
                        <div class="card ms-2" style="width: 22rem;">
                            <div class="card-header"> INFO TRAVAIL </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Poste :</span> {{profil_travailleur.poste}} </li>
                                <li class="list-group-item"> <span>Emploi :</span> {{profil_travailleur.emploi}} </li>
                                <li class="list-group-item"> <span>Horaire :</span> {{profil_travailleur.horaire}} </li>
                                <li class="list-group-item"> <span>Equipe :</span> {{profil_travailleur.equipe | capitalizeFirstLetter}} </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="p-3 mb-3 bg-white shadow-sm border rounded">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div class="d-flex flex-row">
                            <h5>Organigramme lié au travailleur</h5>
                            <div class="spinner-border spinner-border-sm ms-3 mt-1" role="status" *ngIf="loading_organigramme"></div>
                        </div>
                        <div>
                            <button class="btn btn-sm btn-secondary" (click)="zoomIn()"> <i class="bi bi-plus-lg"
                                    style="font-size: 11px;"></i> </button>
                            <button class="btn btn-sm btn-secondary ms-1" (click)="zoomOut()"> <i class="bi bi-dash-lg"
                                    style="font-size: 11px;"></i> </button>
                            <button class="ms-2 btn-special" (click)="zoomToFit()">Ajuster le chart</button>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div id='myDiagramDiv' class="rounded border mt-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>