<app-load-page *ngIf="is_loading"></app-load-page>
<div class="p-3" style="overflow-y: auto; max-height: 91vh;" *ngIf="!is_loading">
    <div class="row">
        <ng-container *ngFor="let departement of liste_departement; let i = index">
            <div class="col-4">
                <div class="card mb-4" style="width: 35rem;">
                    <div class="card-body" appDragdrop (dropped)="onFilesDropped($event, departement)">
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <h6 class="card-title">{{departement.code_organisation}} - {{departement.nom_departement}}</h6>
                        </div>
                        <div class="mt-2">
                            <table class="table table-sm table-bordered"
                                style="text-align: center; vertical-align: middle;">
                                <thead>
                                    <tr>
                                        <th style="width: 71%;">Fichier</th>
                                        <th style="width: 18%;">Dépot</th>
                                        <th style="width: 10%;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let file of departement.data_file; let y = index">
                                        <tr *ngIf="file.id_fichier_departement != null">
                                            <td>{{file.nom_fichier}}</td>
                                            <td>{{file.date | date:"yyyy-MM-dd"}}</td>
                                            <td>
                                                <span class="spinner-border spinner-border-sm me-1" aria-hidden="true" *ngIf="file.is_downloading"></span>
                                                <i class="bi bi-download" style="font-size: 12px;" *ngIf="!file.is_downloading" (click)="downloadFile(file.id_fichier_departement, departement.id_departement, file.nom_fichier, file)"></i>
                                                <i class="bi bi-trash text-danger ms-2" style="font-size: 12px;" *ngIf="access" (click)="deleteFile(file.id_fichier_departement, file)"></i>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>