<!-- <div class="px-4 py-3">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <h3>Liste des formations</h3>
        <button type="button" class="btn btn-sm primary" data-bs-toggle="modal" data-bs-target="#exampleModal" *ngIf="false">Ajouter une formation</button>
    </div>
    <app-load-page *ngIf="is_loading"></app-load-page>
    <div style="overflow: auto; max-height: 86vh;">
        <div class="row mt-3" *ngIf="!is_loading">
            <div class="col-3" *ngFor="let item of liste_formation">
                <div class="form-floating mb-3">
                    <textarea class="form-control" value="{{item.nom_formation | capitalizeFirstLetter}}"
                        placeholder="Leave a comment here" id="floatingTextarea2" readonly></textarea>
                    <label for="floatingTextarea2">{{item.num_formation}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h5>Ajout formation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="num_form" placeholder="name@example.com">
                                <label for="num_form">N° formation *</label>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="nom_form" placeholder="name@example.com">
                                <label for="nom_form">Nom de la formation *</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-sm success">Enregistrer</button>
                        <button class="btn btn-sm secondary ms-2 me-3">Annuler</button>
                        <span class="text-secondary" style="font-size: 11px;">(*) Champ obligatoire</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<span>En cours de développement</span>